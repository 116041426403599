import React from 'react'

const username = "mikeswhitney33";
const email = `${username}@gmail.com`;



// JSX is supported in most places in this template in place of strings
const resume = {
  header: {
    name: 'Michael Whitney',
    links: [
      {
        href: `mailto:${email}`,
        icon: 'far fa-envelope',
        text: email,
      },
      {
        href: `https://github.com/${username}`,
        icon: 'fab fa-github',
        text: `github.com/${username}`,
      },
      {
        href: `https://linkedin.com/in/${username}`,
        icon: 'fab fa-linkedin',
        text: `linkedin.com/in/${username}`,
      },
    ],
  },
  summary: [
    'Seeking {job type} position as a {position type} starting {month} {year}',
    'Graduating {month} {year} with a {degree} in {major}',
    '{number} years of {technical} experience',
    '{current position} at {current company}',
    'Skills: {comma separated list of skills}'
  ],
  sections: [
    {
      title: 'Education',
      subsections: [
        {
          title: <a href="https://byu.edu" target="_blank" rel="noopener noreferrer">{'Brigham Young University'}</a>,
          subtitle: 'Expected Graduation Date: April 2022',
          details: [
            'PhD of Computer Science',
            'Current GPA: 3.85',
            'Completed Coursework: {class names of most notable classes}',
            'Activities: {club or other involvement}'
          ]
        }
      ]
    },
    {
      title: 'Experience',
      subsections: [
        {
          title: <a href="https://techcyte.com" target="_blank" rel="noopener noreferrer">{'Techcyte'}</a>,
          subtitle: 'Applied Data Science Intern - Nov 2019 to Present',
          details: [
            '2-3 verb-driven bullet points that highlight achievements in the form of quantifiable results',
            'Major technologies utilized during the project',
          ]
        },
        {
          title: <a href="https://cs.byu.edu" target="_blank" rel="noopener noreferrer">{'Brigham Young University'}</a>,
          subtitle: 'Graduate Research Assistant - Jan 2018 to Present',
          details: [
            '2-3 verb-driven bullet points that highlight achievements in the form of quantifiable results',
            'Major technologies utilized during the project',
          ]
        },
        {
          title: <a href="https://morinda.com" target="_blank" rel="noopener noreferrer">{'Morinda Inc.'}</a>,
          subtitle: 'Web and Software QA Specialist - Jun 2015 to Aug 2017',
          details: [
            '2-3 verb-driven bullet points that highlight achievements in the form of quantifiable results',
            'Major technologies utilized during the project',
          ]
        }
      ]
    },
    {
      title: 'Service',
      subsections: [
        {
          title: <a href="{organization homepage}" target="_blank" rel="noopener noreferrer">{'organization name'}</a>,
          subtitle: '{position} - {start date} to {end date}',
          details: [
            '2-3 verb-driven bullet points that highlight achievements in the form of quantifiable results',
          ]
        },
      ]
    }
  ]
}

export default resume